import { SlideUpDialog, WtxColors } from '@wavetronix/common-components'
import { useState, useEffect } from 'react'
import { CustomDataGrid, PrimaryButton, SnackbarVariants } from '@wavetronix/common-components'
import CertificationExamsApi from '../../api/CertificationExamsApi'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useSnackbar } from 'notistack'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import ErrorIcon from '@mui/icons-material/Error'
import { Tooltip } from '@mui/material'
import CertificateModal from '../certificate/CertificateModal'
import UsersApi from '../../api/UsersApi'

export default function UserModal(props) {
  const { instance, accounts } = useMsal()
  const [rows, setRows] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const { data, isLoading, refetch } = useQuery(
    'records',
    async () => await CertificationExamsApi.getUserExamRecords(instance, accounts),
    { enabled: false }
  )

  const [modalState, setModalState] = useState(false)
  const [certificateInfo, setCertificateInfo] = useState({})

  const PASS_THRESHOLD = 85
  const EXPIRATION_YEARS = 3
  const ATTEMPTS = 3

  let user = props.user

  const columns = [
    {
      field: 'examName',
      headerName: 'Exam Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'issuer',
      headerName: 'Instructor',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'score',
      headerName: 'Score',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      disableColumnMenu: true,
      renderCell: props => {
        let date = new Date(props.row.date)
        let year = date.getFullYear()
        let expireDate = new Date(date.setFullYear(year + EXPIRATION_YEARS))
        let reminderDate = new Date(expireDate)
        reminderDate.setDate(expireDate.getDate() - 7)
        let now = new Date(Date.now())
        if (expireDate < now) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title='Expired'>
                <CancelIcon sx={{ color: WtxColors.INNOVATION_RED }}></CancelIcon>
              </Tooltip>
              <div>{new Date(props.row.date).toLocaleDateString()}</div>
            </div>
          )
        } else if (reminderDate < now) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title='Near Expired'>
                <ErrorIcon sx={{ color: WtxColors.GOLD }}></ErrorIcon>
              </Tooltip>
              <div>{new Date(props.row.date).toLocaleDateString()}</div>
            </div>
          )
        } else {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title='Not Expired'>
                <CheckCircleIcon sx={{ color: WtxColors.GROWTH_GREEN }}></CheckCircleIcon>
              </Tooltip>
              <div>{new Date(props.row.date).toLocaleDateString()}</div>
            </div>
          )
        }
      }
    },
    {
      field: 'attemptsRemaining',
      headerName: 'Attempts Remaining',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'reset',
      headerName: 'Reset Attempts',
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: props => {
        const onClick = async e => {
          e.stopPropagation()
          let currentRecord = data.filter(record => record.id === props.id)
          let newRecord = { ...currentRecord[0], attemptsRemaining: ATTEMPTS, notified: false }
          await CertificationExamsApi.updateRecord(instance, accounts, newRecord).then(
            _ => {
              enqueueSnackbar('Successfully reset exam attempts', SnackbarVariants.SUCCESS)
            },
            error => {
              console.log(error)
              enqueueSnackbar(`Failed to reset attempts`, SnackbarVariants.ERROR)
            }
          )
          refetch()
        }
        return <PrimaryButton onClick={onClick}>Reset</PrimaryButton>
      }
    },
    {
      field: 'certificate',
      headerName: 'Certificate',
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: props => {
        let date = new Date(props.row.date)
        let expireDate = new Date(new Date(date).setFullYear(new Date(date).getFullYear() + EXPIRATION_YEARS))
        const onClick = async e => {
          e.stopPropagation()
          let issuer = await UsersApi.getUser(props.row.issuerId)
          setCertificateInfo({
            user: `${user.givenName} ${user.surname}`,
            exam: `${props.row.examName}`,
            issuer: `${issuer.givenName} ${issuer.surname}`,
            issueDate: date.toLocaleDateString(),
            expireDate: expireDate.toLocaleDateString()
          })
          setModalState(true)
          refetch()
        }
        return (
          <PrimaryButton disabled={Date.now() > expireDate || props.row.score <= PASS_THRESHOLD} onClick={onClick}>
            Get Certificate
          </PrimaryButton>
        )
      }
    }
  ]

  useEffect(() => {
    refetch()
  }, [props.open, refetch])

  useEffect(() => {
    if (data) {
      let best = {}
      let temp = []
      let entries = data.filter(datum => datum.userId === user.id)
      for (let entry of entries) {
        if (entry) {
          best = entry.submissions.filter(submission => {
            if (submission.grade.percentage === Math.max(...entry.submissions.map(sub => sub.grade.percentage))) {
              return true
            }
            return false
          })[0]
          temp.push({
            id: entry.id,
            examName: entry.examName,
            score: best.grade.percentage,
            date: best.date,
            attemptsRemaining: entry.attemptsRemaining,
            issuer: entry.issuerEmail,
            issuerId: entry.issuerId
          })
        }
        setRows(temp)
      }
    }
  }, [data, user.id])

  return (
    <>
      <CertificateModal
        open={modalState}
        onClose={() => {
          setModalState(false)
        }}
        certificateInfo={certificateInfo}
      ></CertificateModal>
      <SlideUpDialog
        id='userModal'
        fullScreen
        open={props.open}
        onClose={() => {
          setRows([])
          props.onClose()
        }}
        title={
          <h3 style={{ margin: '0px' }}>
            {user.givenName} {user.surname}
          </h3>
        }
      >
        <>
          <div style={{ margin: 10 }}>{user.givenName}'s previous exam attempts</div>
        </>
        <CustomDataGrid autoHeight columns={columns} rows={rows ? rows : []} isLoading={isLoading} />
      </SlideUpDialog>
    </>
  )
}
